import ContainerMain from "pages/common/ContainerMain"

import iconOffer from "images/Channels/SocialCommerce/social_offer_icon.svg"
import iconValues from "images/Channels/SocialCommerce/social_value_icon.svg"

import "./Solutions.scss"
import { useFormatter } from "helpers/i18n"

export default function Solutions() {
  const { __ } = useFormatter()

  return (
    <>
      <div className="nbvcpbrnxb">
        <ContainerMain>
          <div className="xyeviidwvx">
            <div className="section-title jcorzfoekj">
              {__({
                defaultMessage: "CS/CX Solution",
              })}
            </div>
            <div className="yycprueimk">
              {__({
                defaultMessage:
                  "Engage directly with shoppers and give them an enjoyable shopping experience.",
              })}
            </div>
            <div className="zfqabdxwbh">
              <div className="ixyjzbvizx left">
                <div className="zxtvlefixd">
                  <img
                    src={iconOffer}
                    className="cxhcjplmlu"
                    alt="Phone Icon"
                  />
                </div>
                <div className="pdjvgocbof">
                  <div className="rofmxaeavl">
                    {__({
                      defaultMessage: "What We Offer",
                    })}
                  </div>
                  <ul className="iwyukagbct">
                    <li className="bmpvbcrumf">
                      {__({
                        defaultMessage: "Live chat",
                      })}
                    </li>
                    <li className="bmpvbcrumf">
                      {__({
                        defaultMessage: "Q&A",
                      })}
                    </li>
                    <li className="bmpvbcrumf">
                      {__({
                        defaultMessage: "Rating management",
                      })}
                    </li>
                    <li className="bmpvbcrumf">
                      {__({
                        defaultMessage: "Quality assurance",
                      })}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ixyjzbvizx">
                <div className="zxtvlefixd">
                  <img
                    src={iconValues}
                    className="cxhcjplmlu"
                    alt="Phone Icon"
                  />
                </div>
                <div className="pdjvgocbof">
                  <div className="rofmxaeavl">
                    {__({
                      defaultMessage: "Our Values",
                    })}
                  </div>
                  <ul className="iwyukagbct">
                    <li className="bmpvbcrumf">
                      {__({
                        defaultMessage: "Increase conversion rate",
                      })}
                    </li>
                    <li className="bmpvbcrumf">
                      {__({
                        defaultMessage: "Commit on processing time",
                      })}
                    </li>
                    <li className="bmpvbcrumf">
                      {__({
                        defaultMessage: "Maintain customer satisfaction",
                      })}
                    </li>
                    <li className="bmpvbcrumf">
                      {__({
                        defaultMessage: "Enhance customer loyalty",
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
